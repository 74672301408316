.cursor-pointer {
    cursor: pointer;
}


html.dark body, html.dark .bg-light {
    background-color: #121212 !important;
    /* Dark Background */
    color: #e0e0e0 !important;
    /* Light Text */
}

html.dark .navbar, html.dark .card {
    background-color: #1f1f1f !important;
    /* Slightly lighter than body for depth */
}

html.dark .btn-primary {
    background-color: #3f51b5 !important;
    /* Colored button for contrast */
}

html.dark .text-dark {
    color: #e0e0e0 !important;
    /* Light Text */
}

html.dark .text-muted {
    color: #888888 !important;
    /* Slightly lighter text for contrast */
}

html.dark .table {
    background-color: #1f1f1f !important;
    /* Same as .card for consistency */
}

html.dark .table thead th {
    border-bottom-color: #363333 !important;
    /* Slightly lighter text for contrast */
    color: #e0e0e0 !important;
    /* Light Text */
}

html.dark .table tbody td {
    border-top-color: #363333 !important;
    /* Slightly lighter text for contrast */
    color: #e0e0e0 !important;
    /* Light Text */
}

html.dark .list-group-item {
    background-color: #1f1f1f !important;
    /* Same as .card for consistency */
    border-color: #363333 !important;
    /* Slightly lighter text for contrast */
    color: #e0e0e0 !important;
    /* Light Text */
}

html.dark .table th, html.dark .table td {
    background-color: #1f1f1f !important;
    /* Dark Background for table cells */
    border-color: #363333 !important;
    /* Slightly lighter text for contrast */
    color: #e0e0e0 !important;
    /* Light Text */
}

html.dark .border {
    /*border-color: #363333 !important;*/
    /* Slightly lighter text for contrast */
}

html.dark .form-control {
    background-color: #2b2b2b !important;
    /* Dark Background for inputs */
    border-color: #363333 !important;
    /* Slightly lighter for contrast */
    color: #e0e0e0 !important;
    /* Light Text */
}

html.dark .form-control:focus {
    border-color: #3f51b5 !important;
    /* Colored border when focused for contrast */
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, .25) !important;
    /* Box shadow when focused */
}

html.dark .form-control::placeholder {
    color: #888888 !important;
    /* Slightly lighter text for contrast */
}
html.dark .modal-content {
    background-color: #1f1f1f !important;
    /* Dark Background for modal content */
    color: #e0e0e0 !important;
    /* Light Text */
    border-color: #363333 !important;
    /* Slightly lighter for contrast */
}

html.dark .modal-header, html.dark .modal-footer {
    background-color: #2b2b2b !important;
    /* Slightly darker background for header and footer */
    border-color: #363333 !important;
    /* Slightly lighter for contrast */
}

html.dark .modal-body {
    background-color: #1f1f1f !important;
    /* Dark Background for modal body */
    color: #e0e0e0 !important;
    /* Light Text */
}

html.dark .btn-close {
    color: #e0e0e0 !important;
    /* Light Text */
}

html.dark .modal-backdrop {
    background-color: rgba(0, 0, 0, 0.5) !important;
    /* Semi-transparent black backdrop */
}

html.dark .modal-dialog {
    border-color: #363333 !important;
    /* Slightly lighter border color for contrast */
}

html.dark .modal-title {
    color: #e0e0e0 !important;
    /* Light Text for title */
}

html.dark .modal .btn-primary {
    background-color: #3f51b5 !important;
    /* Colored button for contrast */
    border-color: #3f51b5 !important;
}

html.dark .modal .btn-secondary {
    background-color: #2b2b2b !important;
    /* Dark Background for secondary button */
    color: #e0e0e0 !important;
    /* Light Text */
}

html.dark .modal-backdrop {
    background-color: rgba(0, 0, 0, 0.9) !important; /* Even more opaque black backdrop */
}

.small-dropdown {
    padding: 0.25rem 0.5rem; /* Adjust padding as needed */
    font-size: 0.75rem; /* Adjust font size as needed */
}

.small-dropdown .dropdown-menu {
    padding: 0; /* Remove padding around the dropdown menu */
}

.small-dropdown .dropdown-item {
    padding: 0.25rem 0.5rem; /* Adjust padding for each item */
    font-size: 0.75rem; /* Adjust font size for each item */
}